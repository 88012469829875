@tailwind components;
@tailwind utilities;

@layer components {
  /* .all-\[unset\] {
        all: unset;
    } */

  .skewed-parallelogram-left {
    transform: skewX(-6deg);
    rotate: -6deg;
    margin-left: -3px;
    /* width: */
  }

  .skewed-parallelogram-right {
    transform: skewX(-6deg);
    rotate: -6deg;
    /* width: */
  }

  /* LEFT BANDS */

  .left-band-1 {
    width: calc(var(--screen-width-px) / 3);
    height: calc(var(--screen-height-px) / 14);
    background: rgba(244, 78, 24, 0.5);
    top: max(var(--left-bands-vh), var(--left-bands-minimum-height));
  }
  /* minimum 600px,  */

  .left-band-2 {
    width: calc(var(--screen-width-px) / 4.28);
    height: calc(var(--screen-height-px) / 14);
    background: rgba(255, 138, 0, 0.5);
    top: calc(
      max(var(--left-bands-vh), var(--left-bands-minimum-height)) +
        (var(--screen-height-px) / 14) + 8px
    );
  }

  .left-band-3 {
    width: calc(var(--screen-width-px) / 6.85);
    height: calc(var(--screen-height-px) / 14);
    background: rgba(255, 199, 0, 0.5);
    top: calc(
      max(var(--left-bands-vh), var(--left-bands-minimum-height)) + 2 *
        (var(--screen-height-px) / 14) + 15px
    );
  }

  /* Mobile: make bands 90px lower than programmed for desktop */
  @media (max-width: 767px) {
    .left-band-1 {
      width: calc(var(--screen-width-px) / 3);
      height: calc(var(--screen-height-px) / var(--band-width-divisor-mobile));
      background: rgba(244, 78, 24, 0.5);
      top: calc(
        max(var(--left-bands-vh), var(--left-bands-minimum-height)) + 110px
      );
    }
    /* minimum 600px,  */

    .left-band-2 {
      width: calc(var(--screen-width-px) / 4.28);
      height: calc(var(--screen-height-px) / var(--band-width-divisor-mobile));
      background: rgba(255, 138, 0, 0.5);
      top: calc(
        max(var(--left-bands-vh), var(--left-bands-minimum-height)) +
          (var(--screen-height-px) / var(--band-width-divisor-mobile)) + 8px +
          110px
      );
    }

    .left-band-3 {
      width: calc(var(--screen-width-px) / 6.85);
      height: calc(var(--screen-height-px) / var(--band-width-divisor-mobile));
      background: rgba(255, 199, 0, 0.5);
      top: calc(
        max(var(--left-bands-vh), var(--left-bands-minimum-height)) + 2 *
          (var(--screen-height-px) / var(--band-width-divisor-mobile)) + 15px +
          110px
      );
    }
  }

  /* END OF LEFT BANDS */
  /* RIGHT BANDS */

  .right-band-1 {
    width: calc(var(--screen-width-px) / 6.85);
    height: calc(var(--screen-height-px) / 14);
    background: rgba(88, 184, 254, 0.5);
    top: max(56vh, 580px);
  }

  .right-band-2 {
    width: calc(var(--screen-width-px) / 4.28);
    height: calc(var(--screen-height-px) / 14);
    background: rgba(49, 115, 255, 0.5);
    top: calc(max(56vh, 580px) + (var(--screen-height-px) / 14) + 7px);
  }

  .right-band-3 {
    width: calc(var(--screen-width-px) / 3);
    height: calc(var(--screen-height-px) / 14);
    background: rgba(200, 89, 202, 0.5);
    top: calc(max(56vh, 580px) + 2 * (var(--screen-height-px) / 14) + 15px);
  }

  /* Mobile: make bands 90px lower than programmed for desktop */
  @media (max-width: 767px) {
    .right-band-1 {
      width: calc(var(--screen-width-px) / 6.85);
      height: calc(var(--screen-height-px) / var(--band-width-divisor-mobile));
      background: rgba(88, 184, 254, 0.5);
      top: calc(max(56vh, 580px) + 200px);
    }

    .right-band-2 {
      width: calc(var(--screen-width-px) / 4.28);
      height: calc(var(--screen-height-px) / var(--band-width-divisor-mobile));
      background: rgba(49, 115, 255, 0.5);
      top: calc(
        max(56vh, 580px) +
          (var(--screen-height-px) / var(--band-width-divisor-mobile)) + 7px +
          200px
      );
    }

    .right-band-3 {
      width: calc(var(--screen-width-px) / 3);
      height: calc(var(--screen-height-px) / var(--band-width-divisor-mobile));
      background: rgba(200, 89, 202, 0.5);
      top: calc(
        max(56vh, 580px) + 2 *
          (var(--screen-height-px) / var(--band-width-divisor-mobile)) + 15px +
          200px
      );
    }
  }

  /* END OF RIGHT BANDS */

  .bg-clip-text-custom {
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bg-clip-text-pink-to-lilac-gradient {
    background: linear-gradient(
      45deg,
      rgb(247, 201, 255),
      rgb(209, 159, 255) 100%
    );
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bg-clip-text-blue-to-teal-gradient {
    background: linear-gradient(
      45deg,
      rgb(133, 211, 255),
      rgb(96, 222, 207) 100%
    );
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bg-clip-text-pink-to-violet-gradient {
    background: linear-gradient(
      45deg,
      rgb(255, 182, 193),
      rgb(253, 128, 226) 100%
    );
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bg-clip-text-pink-to-blue-gradient {
    /* background: linear-gradient(
      45deg,
      rgb(212, 81, 255) 0%,
      rgb(97, 71, 255) 47%,
      rgb(61, 29, 255) 100%
    ); */
    background: linear-gradient(
      45deg,
      rgb(212, 81, 255) 0%,
      rgb(130, 78, 249) 55%,
      rgb(61, 29, 255) 100%
    );
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .rainbow-gradient {
    background: linear-gradient(
      90deg,
      rgb(255, 137.7, 0) 0%,
      rgb(243.85, 78.36, 27.4) 25%,
      rgb(234.45, 28.33, 50.5) 50%,
      rgb(183.22, 33.17, 143.97) 65.66%,
      rgb(152.67, 36.06, 199.74) 75%,
      rgb(49.23, 114.7, 255) 100%
    );
  }

  .bg-clip-text-full-rainbow-gradient {
    background: linear-gradient(
      90deg,
      rgb(255, 137.7, 0) 0%,
      rgb(243.85, 78.36, 27.4) 25%,
      rgb(234.45, 28.33, 50.5) 50%,
      rgb(183.22, 33.17, 143.97) 65.66%,
      rgb(152.67, 36.06, 199.74) 75%,
      rgb(49.23, 114.7, 255) 100%
    );
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 767px) {
    .bg-clip-text-full-rainbow-gradient {
      background: linear-gradient(
        325deg,
        rgb(255, 137.7, 0) 0%,
        rgb(243.85, 78.36, 27.4) 25%,
        rgb(234.45, 28.33, 50.5) 50%,
        rgb(183.22, 33.17, 143.97) 65.66%,
        rgb(152.67, 36.06, 199.74) 75%,
        rgb(49.23, 114.7, 255) 100%
      );
      background-clip: text;
      color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .bg-clip-text-yellow-to-orange-gradient {
    /* rgb(255, 221, 0) */
    background: linear-gradient(
      45deg,
      rgb(255, 150, 0) 0%,
      rgb(244, 78, 24) 51.5%,
      rgb(253.22, 7.74, 35.15) 100%
    );
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bg-clip-text-violet-to-blue-gradient {
    /* background: linear-gradient(45deg, rgb(88.48, 36, 200) 0%, rgb(76.32, 41.57, 215.33) 35.67%, rgb(20.09, 76.29, 195.49) 66.67%, rgb(5.23, 63.92, 188.41) 99.67%); */
    /* background: linear-gradient(
      45deg,
      rgb(88.48, 36, 200) 0%,
      rgb(76.32, 41.57, 215.33) 35.67%,
      rgb(20.09, 76.29, 195.49) 99.67%
    ); */
    background: linear-gradient(
      45deg,
      rgb(70, 6, 206) 0%,
      rgb(76.32, 41.57, 215.33) 35.67%,
      rgb(20.09, 76.29, 195.49) 99.67%
    );
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bg-clip-text-violet-to-blue-gradient-mobile {
    /* background: linear-gradient(45deg, rgb(88.48, 36, 200) 0%, rgb(76.32, 41.57, 215.33) 35.67%, rgb(20.09, 76.29, 195.49) 66.67%, rgb(5.23, 63.92, 188.41) 99.67%); */
    background: linear-gradient(
      90deg,
      rgb(88.48, 36, 200) 0%,
      rgb(76.32, 41.57, 215.33) 35.67%,
      rgb(20.09, 76.29, 195.49) 99.67%
    );
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .playwrite-hr-font {
    font-family: "Playwrite HR", cursive;
    font-optical-sizing: auto;
    font-weight: 300; /* 100-400 range */
    color: rgb(86, 86, 86);
    font-style: normal;
  }
}

:root {
  --screen-height-px: 1080px;
  --screen-width-px: 1920px;
  --band-width-divisor: 14;
  --band-width-divisor-mobile: 22;
  --left-bands-minimum-height: 700px;
  /* Height we want left and right bands to be based on viewport height */
  --left-bands-vh: 70vh;
  --body-lg-regular-font-family: "Inter", Helvetica;
  --body-lg-regular-font-size: 18px;
  --body-lg-regular-font-style: normal;
  --body-lg-regular-font-weight: 400;
  --body-lg-regular-letter-spacing: 0px;
  --body-lg-regular-line-height: 28px;
  --body-md-semibold-font-family: "Inter", Helvetica;
  --body-md-semibold-font-size: 16px;
  --body-md-semibold-font-style: normal;
  --body-md-semibold-font-weight: 600;
  --body-md-semibold-letter-spacing: 0px;
  --body-md-semibold-line-height: 24px;
  --body-sm-semibold-font-family: "Inter", Helvetica;
  --body-sm-semibold-font-size: 14px;
  --body-sm-semibold-font-style: normal;
  --body-sm-semibold-font-weight: 600;
  --body-sm-semibold-letter-spacing: 0px;
  --body-sm-semibold-line-height: 20px;
  --body-xs-semibold-font-family: "Inter", Helvetica;
  --body-xs-semibold-font-size: 12px;
  --body-xs-semibold-font-style: normal;
  --body-xs-semibold-font-weight: 600;
  --body-xs-semibold-letter-spacing: 0px;
  --body-xs-semibold-line-height: 16px;
  --display-lg-bold-font-family: "Inter", Helvetica;
  --display-lg-bold-font-size: 48px;
  --display-lg-bold-font-style: normal;
  --display-lg-bold-font-weight: 700;
  --display-lg-bold-letter-spacing: -1.2px;
  --display-lg-bold-line-height: 60px;
  --display-xl-bold-font-family: "Inter", Helvetica;
  --display-xl-bold-font-size: 60px;
  --display-xl-bold-font-style: normal;
  --display-xl-bold-font-weight: 700;
  --display-xl-bold-letter-spacing: -1.5px;
  --display-xl-bold-line-height: 72px;
  --neutral-700: rgba(55, 65, 81, 1);
  --neutral-900: rgba(17, 24, 39, 1);
  --primary-100: rgba(237, 233, 254, 1);
  --primary-600: rgba(124, 58, 237, 1);
  --variable-collection-blue-m: rgba(49, 115, 255, 1);
  --variable-collection-body-text: rgba(17, 24, 39, 1);
  --variable-collection-dark-orange-m: rgba(244, 78, 24, 1);
  --variable-collection-light-lilac-d: rgba(237, 233, 254, 1);
  --variable-collection-light-orange-m: rgba(255, 138, 0, 1);
  --variable-collection-lilac-d: rgba(196, 181, 253, 1);
  --variable-collection-purple-m: rgba(153, 36, 200, 1);
  --variable-collection-red-m: rgba(234, 28, 51, 1);
  --variable-collection-turquoise-d: rgba(117, 227, 234, 1);
  --white: rgba(255, 255, 255, 1);
}
