.skewed-parallelogram-left {
  margin-left: -3px;
  transform: skewX(-6deg)rotate3d(0, 0, 1, -6deg);
}

.skewed-parallelogram-right {
  transform: skewX(-6deg)rotate3d(0, 0, 1, -6deg);
}

.left-band-1 {
  width: calc(var(--screen-width-px) / 3);
  height: calc(var(--screen-height-px) / 14);
  top: max(var(--left-bands-vh), var(--left-bands-minimum-height));
  background: #f44e1880;
}

.left-band-2 {
  width: calc(var(--screen-width-px) / 4.28);
  height: calc(var(--screen-height-px) / 14);
  top: calc(max(var(--left-bands-vh), var(--left-bands-minimum-height))  + (var(--screen-height-px) / 14)  + 8px);
  background: #ff8a0080;
}

.left-band-3 {
  width: calc(var(--screen-width-px) / 6.85);
  height: calc(var(--screen-height-px) / 14);
  top: calc(max(var(--left-bands-vh), var(--left-bands-minimum-height))  + 2 * (var(--screen-height-px) / 14)  + 15px);
  background: #ffc70080;
}

@media (width <= 767px) {
  .left-band-1 {
    width: calc(var(--screen-width-px) / 3);
    height: calc(var(--screen-height-px) / var(--band-width-divisor-mobile));
    top: calc(max(var(--left-bands-vh), var(--left-bands-minimum-height))  + 110px);
    background: #f44e1880;
  }

  .left-band-2 {
    width: calc(var(--screen-width-px) / 4.28);
    height: calc(var(--screen-height-px) / var(--band-width-divisor-mobile));
    top: calc(max(var(--left-bands-vh), var(--left-bands-minimum-height))  + (var(--screen-height-px) / var(--band-width-divisor-mobile))  + 8px + 110px);
    background: #ff8a0080;
  }

  .left-band-3 {
    width: calc(var(--screen-width-px) / 6.85);
    height: calc(var(--screen-height-px) / var(--band-width-divisor-mobile));
    top: calc(max(var(--left-bands-vh), var(--left-bands-minimum-height))  + 2 * (var(--screen-height-px) / var(--band-width-divisor-mobile))  + 15px + 110px);
    background: #ffc70080;
  }
}

.right-band-1 {
  width: calc(var(--screen-width-px) / 6.85);
  height: calc(var(--screen-height-px) / 14);
  background: #58b8fe80;
  top: max(56vh, 580px);
}

.right-band-2 {
  width: calc(var(--screen-width-px) / 4.28);
  height: calc(var(--screen-height-px) / 14);
  top: calc(max(56vh, 580px)  + (var(--screen-height-px) / 14)  + 7px);
  background: #3173ff80;
}

.right-band-3 {
  width: calc(var(--screen-width-px) / 3);
  height: calc(var(--screen-height-px) / 14);
  top: calc(max(56vh, 580px)  + 2 * (var(--screen-height-px) / 14)  + 15px);
  background: #c859ca80;
}

@media (width <= 767px) {
  .right-band-1 {
    width: calc(var(--screen-width-px) / 6.85);
    height: calc(var(--screen-height-px) / var(--band-width-divisor-mobile));
    background: #58b8fe80;
    top: calc(max(56vh, 580px) + 200px);
  }

  .right-band-2 {
    width: calc(var(--screen-width-px) / 4.28);
    height: calc(var(--screen-height-px) / var(--band-width-divisor-mobile));
    top: calc(max(56vh, 580px)  + (var(--screen-height-px) / var(--band-width-divisor-mobile))  + 7px + 200px);
    background: #3173ff80;
  }

  .right-band-3 {
    width: calc(var(--screen-width-px) / 3);
    height: calc(var(--screen-height-px) / var(--band-width-divisor-mobile));
    top: calc(max(56vh, 580px)  + 2 * (var(--screen-height-px) / var(--band-width-divisor-mobile))  + 15px + 200px);
    background: #c859ca80;
  }
}

.bg-clip-text-custom {
  color: #0000;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.bg-clip-text-pink-to-lilac-gradient {
  color: #0000;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, #f7c9ff, #d19fff 100%) text;
}

.bg-clip-text-blue-to-teal-gradient {
  color: #0000;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, #85d3ff, #60decf 100%) text;
}

.bg-clip-text-pink-to-violet-gradient {
  color: #0000;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, #ffb6c1, #fd80e2 100%) text;
}

.bg-clip-text-pink-to-blue-gradient {
  color: #0000;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, #d451ff 0%, #824ef9 55%, #3d1dff 100%) text;
}

.bg-clip-text-full-rainbow-gradient {
  color: #0000;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #ff8a00 0%, #f44e1b 25%, #ea1c33 50%, #b72190 65.66%, #9924c8 75%, #3173ff 100%) text;
}

@media (width <= 767px) {
  .bg-clip-text-full-rainbow-gradient {
    color: #0000;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(325deg, #ff8a00 0%, #f44e1b 25%, #ea1c33 50%, #b72190 65.66%, #9924c8 75%, #3173ff 100%) text;
  }
}

.bg-clip-text-yellow-to-orange-gradient {
  color: #0000;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, #ff9600 0%, #f44e18 51.5%, #fd0823 100%) text;
}

.bg-clip-text-violet-to-blue-gradient {
  color: #0000;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(45deg, #4606ce 0%, #4c2ad7 35.67%, #144cc3 99.67%) text;
}

.playwrite-hr-font {
  font-optical-sizing: auto;
  color: #565656;
  font-family: Playwrite HR, cursive;
  font-style: normal;
  font-weight: 300;
}

.\!absolute {
  position: absolute !important;
}

.absolute {
  position: absolute;
}

.\!relative {
  position: relative !important;
}

.relative {
  position: relative;
}

.\!left-\[274px\] {
  left: 274px !important;
}

.\!left-\[827px\] {
  left: 827px !important;
}

.\!top-\[135px\] {
  top: 135px !important;
}

.\!top-\[466px\] {
  top: 466px !important;
}

.-bottom-2 {
  bottom: -.5rem;
}

.-left-3 {
  left: -.75rem;
}

.-left-4 {
  left: -1rem;
}

.-left-px {
  left: -1px;
}

.-top-4 {
  top: -1rem;
}

.-top-px {
  top: -1px;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-48 {
  left: 12rem;
}

.left-\[1149px\] {
  left: 1149px;
}

.left-\[1260px\] {
  left: 1260px;
}

.left-\[15\%\] {
  left: 15%;
}

.left-\[163px\] {
  left: 163px;
}

.left-\[185px\] {
  left: 185px;
}

.left-\[209px\] {
  left: 209px;
}

.left-\[274px\] {
  left: 274px;
}

.left-\[275px\] {
  left: 275px;
}

.left-\[298px\] {
  left: 298px;
}

.left-\[33\%\] {
  left: 33%;
}

.left-\[366px\] {
  left: 366px;
}

.left-\[413px\] {
  left: 413px;
}

.left-\[414px\] {
  left: 414px;
}

.left-\[475px\] {
  left: 475px;
}

.left-\[50\%\] {
  left: 50%;
}

.left-\[564px\] {
  left: 564px;
}

.left-\[61px\] {
  left: 61px;
}

.left-\[773px\] {
  left: 773px;
}

.left-\[828px\] {
  left: 828px;
}

.left-\[864px\] {
  left: 864px;
}

.left-\[871px\] {
  left: 871px;
}

.left-\[889px\] {
  left: 889px;
}

.left-\[934px\] {
  left: 934px;
}

.left-\[988px\] {
  left: 988px;
}

.left-px {
  left: 1px;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-1\/4 {
  top: 25%;
}

.top-\[135px\] {
  top: 135px;
}

.top-\[1381px\] {
  top: 1381px;
}

.top-\[166px\] {
  top: 166px;
}

.top-\[187px\] {
  top: 187px;
}

.top-\[189px\] {
  top: 189px;
}

.top-\[2013px\] {
  top: 2013px;
}

.top-\[220px\] {
  top: 220px;
}

.top-\[229px\] {
  top: 229px;
}

.top-\[243px\] {
  top: 243px;
}

.top-\[2624px\] {
  top: 2624px;
}

.top-\[377px\] {
  top: 377px;
}

.top-\[379px\] {
  top: 379px;
}

.top-\[417px\] {
  top: 417px;
}

.top-\[418px\] {
  top: 418px;
}

.top-\[432px\] {
  top: 432px;
}

.top-\[448px\] {
  top: 448px;
}

.top-\[508px\] {
  top: 508px;
}

.top-\[50px\] {
  top: 50px;
}

.top-\[52px\] {
  top: 52px;
}

.top-\[541px\] {
  top: 541px;
}

.top-\[553px\] {
  top: 553px;
}

.top-\[63px\] {
  top: 63px;
}

.top-\[640px\] {
  top: 640px;
}

.top-\[66px\] {
  top: 66px;
}

.top-\[718px\] {
  top: 718px;
}

.top-\[849px\] {
  top: 849px;
}

.top-\[88px\] {
  top: 88px;
}

.top-\[9px\] {
  top: 9px;
}

.-z-50 {
  z-index: -50;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.\!mb-\[-1\.50px\] {
  margin-bottom: -1.5px !important;
}

.\!ml-\[-7\.00px\] {
  margin-left: -7px !important;
}

.\!mr-\[-7\.00px\] {
  margin-right: -7px !important;
}

.\!mt-\[-1\.50px\] {
  margin-top: -1.5px !important;
}

.-ml-\[22\%\] {
  margin-left: -22%;
}

.-ml-\[25\%\] {
  margin-left: -25%;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-3\.5 {
  margin-bottom: .875rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[-1\.00px\] {
  margin-top: -1px;
}

.\!flex {
  display: flex !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.hidden {
  display: none;
}

.\!h-4 {
  height: 1rem !important;
}

.\!h-5 {
  height: 1.25rem !important;
}

.\!h-6 {
  height: 1.5rem !important;
}

.\!h-\[53px\] {
  height: 53px !important;
}

.h-1 {
  height: .25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-7 {
  height: 1.75rem;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[113px\] {
  height: 113px;
}

.h-\[117px\] {
  height: 117px;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[121px\] {
  height: 121px;
}

.h-\[125px\] {
  height: 125px;
}

.h-\[127px\] {
  height: 127px;
}

.h-\[133px\] {
  height: 133px;
}

.h-\[135px\] {
  height: 135px;
}

.h-\[141px\] {
  height: 141px;
}

.h-\[150px\] {
  height: 150px;
}

.h-\[180px\] {
  height: 180px;
}

.h-\[185px\] {
  height: 185px;
}

.h-\[187px\] {
  height: 187px;
}

.h-\[201px\] {
  height: 201px;
}

.h-\[221px\] {
  height: 221px;
}

.h-\[257px\] {
  height: 257px;
}

.h-\[259px\] {
  height: 259px;
}

.h-\[2881px\] {
  height: 2881px;
}

.h-\[322px\] {
  height: 322px;
}

.h-\[497px\] {
  height: 497px;
}

.h-\[53px\] {
  height: 53px;
}

.h-\[587px\] {
  height: 587px;
}

.h-\[632px\] {
  height: 632px;
}

.h-\[66px\] {
  height: 66px;
}

.h-\[752px\] {
  height: 752px;
}

.h-\[75px\] {
  height: 75px;
}

.h-\[835px\] {
  height: 835px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-none {
  max-height: none;
}

.min-h-\[1000px\] {
  min-height: 1000px;
}

.\!w-4 {
  width: 1rem !important;
}

.\!w-5 {
  width: 1.25rem !important;
}

.\!w-6 {
  width: 1.5rem !important;
}

.\!w-\[377px\] {
  width: 377px !important;
}

.w-6 {
  width: 1.5rem;
}

.w-\[1023px\] {
  width: 1023px;
}

.w-\[1074px\] {
  width: 1074px;
}

.w-\[12\%\] {
  width: 12%;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[125px\] {
  width: 125px;
}

.w-\[127px\] {
  width: 127px;
}

.w-\[138px\] {
  width: 138px;
}

.w-\[1440px\] {
  width: 1440px;
}

.w-\[1441px\] {
  width: 1441px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[187px\] {
  width: 187px;
}

.w-\[20\%\] {
  width: 20%;
}

.w-\[22\%\] {
  width: 22%;
}

.w-\[243px\] {
  width: 243px;
}

.w-\[253px\] {
  width: 253px;
}

.w-\[263px\] {
  width: 263px;
}

.w-\[268px\] {
  width: 268px;
}

.w-\[278px\] {
  width: 278px;
}

.w-\[30\%\] {
  width: 30%;
}

.w-\[311px\] {
  width: 311px;
}

.w-\[375px\] {
  width: 375px;
}

.w-\[377px\] {
  width: 377px;
}

.w-\[40\%\] {
  width: 40%;
}

.w-\[420px\] {
  width: 420px;
}

.w-\[429px\] {
  width: 429px;
}

.w-\[50\%\] {
  width: 50%;
}

.w-\[55\%\] {
  width: 55%;
}

.w-\[610px\] {
  width: 610px;
}

.w-\[612px\] {
  width: 612px;
}

.w-\[648px\] {
  width: 648px;
}

.w-\[70\%\] {
  width: 70%;
}

.w-\[75px\] {
  width: 75px;
}

.w-\[76\%\] {
  width: 76%;
}

.w-\[892px\] {
  width: 892px;
}

.w-\[893px\] {
  width: 893px;
}

.w-\[full\] {
  width: full;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.min-w-44 {
  min-width: 11rem;
}

.min-w-56 {
  min-width: 14rem;
}

.min-w-\[175px\] {
  min-width: 175px;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-\[120px\] {
  max-width: 120px;
}

.max-w-\[1400px\] {
  max-width: 1400px;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-\[900px\] {
  max-width: 900px;
}

.max-w-none {
  max-width: none;
}

.\!flex-\[0_0_auto\] {
  flex: none !important;
}

.flex-\[0_0_auto\] {
  flex: none;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.5\] {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-2 {
  gap: .5rem;
}

.self-stretch {
  align-self: stretch;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-balance {
  text-wrap: balance;
}

.text-pretty {
  text-wrap: pretty;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[10px_10px_0px_0px\] {
  border-radius: 10px 10px 0 0;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border-none {
  border-style: none;
}

.border-violet-600 {
  --tw-border-opacity: 1;
  border-color: rgb(124 58 237 / var(--tw-border-opacity));
}

.\!bg-violet-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#f2f7ffb2\] {
  background-color: #f2f7ffb2;
}

.bg-\[\#ffffff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-primary-100 {
  background-color: var(--primary-100);
}

.bg-primary-600 {
  background-color: var(--primary-600);
}

.bg-transparent {
  background-color: #0000;
}

.bg-violet-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity));
}

.bg-white {
  background-color: var(--white);
}

.bg-\[url\(https\:\/\/c\.animaapp\.com\/GnD7RgU1\/img\/frame-8\.svg\)\] {
  background-image: url("https://c.animaapp.com/GnD7RgU1/img/frame-8.svg");
}

.bg-cover {
  background-size: cover;
}

.bg-clip-text {
  background-clip: text;
}

.bg-\[100\%_100\%\] {
  background-position: 100% 100%;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pb-\[5\.5rem\] {
  padding-bottom: 5.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.text-center {
  text-align: center;
}

.font-body-md-semibold {
  font-family: var(--body-md-semibold-font-family);
}

.font-body-sm-semibold {
  font-family: var(--body-sm-semibold-font-family);
}

.font-body-xs-semibold {
  font-family: var(--body-xs-semibold-font-family);
}

.font-display-lg-bold {
  font-family: var(--display-lg-bold-font-family);
}

.font-display-xl-bold {
  font-family: var(--display-xl-bold-font-family);
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[17px\] {
  font-size: 17px;
}

.text-\[45px\] {
  font-size: 45px;
}

.text-\[60px\] {
  font-size: 60px;
}

.text-\[length\:var\(--body-md-semibold-font-size\)\] {
  font-size: var(--body-md-semibold-font-size);
}

.text-\[length\:var\(--body-sm-semibold-font-size\)\] {
  font-size: var(--body-sm-semibold-font-size);
}

.text-\[length\:var\(--body-xs-semibold-font-size\)\] {
  font-size: var(--body-xs-semibold-font-size);
}

.text-\[length\:var\(--display-lg-bold-font-size\)\] {
  font-size: var(--display-lg-bold-font-size);
}

.text-\[length\:var\(--display-xl-bold-font-size\)\] {
  font-size: var(--display-xl-bold-font-size);
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-\[350\] {
  font-weight: 350;
}

.font-\[410\] {
  font-weight: 410;
}

.font-\[500\] {
  font-weight: 500;
}

.font-\[number\:var\(--body-md-semibold-font-weight\)\] {
  font-weight: var(--body-md-semibold-font-weight);
}

.font-\[number\:var\(--body-sm-semibold-font-weight\)\] {
  font-weight: var(--body-sm-semibold-font-weight);
}

.font-\[number\:var\(--body-xs-semibold-font-weight\)\] {
  font-weight: var(--body-xs-semibold-font-weight);
}

.font-\[number\:var\(--display-lg-bold-font-weight\)\] {
  font-weight: var(--display-lg-bold-font-weight);
}

.font-\[number\:var\(--display-xl-bold-font-weight\)\] {
  font-weight: var(--display-xl-bold-font-weight);
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-\[19px\] {
  line-height: 19px;
}

.leading-\[22px\] {
  line-height: 22px;
}

.leading-\[60px\] {
  line-height: 60px;
}

.leading-\[72px\] {
  line-height: 72px;
}

.leading-\[var\(--body-md-semibold-line-height\)\] {
  line-height: var(--body-md-semibold-line-height);
}

.leading-\[var\(--body-sm-semibold-line-height\)\] {
  line-height: var(--body-sm-semibold-line-height);
}

.leading-\[var\(--body-xs-semibold-line-height\)\] {
  line-height: var(--body-xs-semibold-line-height);
}

.leading-\[var\(--display-lg-bold-line-height\)\] {
  line-height: var(--display-lg-bold-line-height);
}

.leading-\[var\(--display-xl-bold-line-height\)\] {
  line-height: var(--display-xl-bold-line-height);
}

.leading-relaxed {
  line-height: 1.625;
}

.tracking-\[-1\.20px\] {
  letter-spacing: -1.2px;
}

.tracking-\[0\.00px\] {
  letter-spacing: 0;
}

.tracking-\[0\.36px\] {
  letter-spacing: .36px;
}

.tracking-\[0\] {
  letter-spacing: 0;
}

.tracking-\[3\.00px\] {
  letter-spacing: 3px;
}

.tracking-\[var\(--body-md-semibold-letter-spacing\)\] {
  letter-spacing: var(--body-md-semibold-letter-spacing);
}

.tracking-\[var\(--body-sm-semibold-letter-spacing\)\] {
  letter-spacing: var(--body-sm-semibold-letter-spacing);
}

.tracking-\[var\(--body-xs-semibold-letter-spacing\)\] {
  letter-spacing: var(--body-xs-semibold-letter-spacing);
}

.tracking-\[var\(--display-lg-bold-letter-spacing\)\] {
  letter-spacing: var(--display-lg-bold-letter-spacing);
}

.tracking-\[var\(--display-xl-bold-letter-spacing\)\] {
  letter-spacing: var(--display-xl-bold-letter-spacing);
}

.tracking-normal {
  letter-spacing: 0;
}

.\!text-\[\#ffffff\] {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.\!text-primary-600 {
  color: var(--primary-600) !important;
}

.\!text-violet-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(124 58 237 / var(--tw-text-opacity)) !important;
}

.text-\[\#3a465b\] {
  --tw-text-opacity: 1;
  color: rgb(58 70 91 / var(--tw-text-opacity));
}

.text-\[\#6c768b\] {
  --tw-text-opacity: 1;
  color: rgb(108 118 139 / var(--tw-text-opacity));
}

.text-\[\#ed8205\] {
  --tw-text-opacity: 1;
  color: rgb(237 130 5 / var(--tw-text-opacity));
}

.text-\[\#ffffff\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.text-neutral-700 {
  color: var(--neutral-700);
}

.text-neutral-900 {
  color: var(--neutral-900);
}

.text-transparent {
  color: #0000;
}

.text-violet-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

.text-white {
  color: var(--white);
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-blend-darken {
  background-blend-mode: darken;
}

.outline {
  outline-style: solid;
}

.saturate-100 {
  --tw-saturate: saturate(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-\[100px\] {
  --tw-backdrop-blur: blur(100px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-\[100\%\] {
  --tw-backdrop-brightness: brightness(100%);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\[-webkit-backdrop-filter\:blur\(100px\)_brightness\(100\%\)\] {
  -webkit-backdrop-filter: blur(100px) brightness();
}

.\[-webkit-background-clip\:text\] {
  -webkit-background-clip: text;
}

.\[-webkit-text-fill-color\:transparent\] {
  -webkit-text-fill-color: transparent;
}

.\[background\:linear-gradient\(180deg\,rgb\(255\,137\.7\,0\)_0\%\,rgb\(243\.85\,78\.36\,27\.4\)_25\%\,rgb\(234\.45\,28\.33\,50\.5\)_50\%\,rgb\(183\.22\,33\.17\,143\.97\)_65\.66\%\,rgb\(152\.67\,36\.06\,199\.74\)_75\%\,rgb\(49\.23\,114\.7\,255\)_100\%\)\] {
  background: linear-gradient(#ff8a00 0%, #f44e1b 25%, #ea1c33 50%, #b72190 65.66%, #9924c8 75%, #3173ff 100%);
}

.\[font-family\:\'Allura\'\,Helvetica\] {
  font-family: Allura, Helvetica;
}

.\[font-family\:\'Calibri-Italic\'\,Helvetica\] {
  font-family: Calibri-Italic, Helvetica;
}

.\[font-family\:\'Calibri-Regular\'\,Helvetica\] {
  font-family: Calibri-Regular, Helvetica;
}

.\[font-family\:\'Inria_Sans\'\,Helvetica\] {
  font-family: Inria Sans, Helvetica;
}

.\[font-family\:\'Inter\'\,Helvetica\] {
  font-family: Inter, Helvetica;
}

.\[font-family\:\'Source_Sans_3\'\,Helvetica\] {
  font-family: "Source Sans 3", Helvetica;
}

.\[font-family\:\'Source_Sans_Pro\'\,Helvetica\] {
  font-family: Source Sans Pro, Helvetica;
}

.\[font-family\:var\(--display-xl-bold-font-family\)\] {
  font-family: var(--display-xl-bold-font-family);
}

.\[font-style\:var\(--body-md-semibold-font-style\)\] {
  font-style: var(--body-md-semibold-font-style);
}

.\[font-style\:var\(--body-sm-semibold-font-style\)\] {
  font-style: var(--body-sm-semibold-font-style);
}

.\[font-style\:var\(--body-xs-semibold-font-style\)\] {
  font-style: var(--body-xs-semibold-font-style);
}

.\[font-style\:var\(--display-lg-bold-font-style\)\] {
  font-style: var(--display-lg-bold-font-style);
}

.\[font-style\:var\(--display-xl-bold-font-style\)\] {
  font-style: var(--display-xl-bold-font-style);
}

.\[text-fill-color\:transparent\] {
  text-fill-color: transparent;
}

:root {
  --screen-height-px: 1080px;
  --screen-width-px: 1920px;
  --band-width-divisor: 14;
  --band-width-divisor-mobile: 22;
  --left-bands-minimum-height: 700px;
  --left-bands-vh: 70vh;
  --body-lg-regular-font-family: "Inter", Helvetica;
  --body-lg-regular-font-size: 18px;
  --body-lg-regular-font-style: normal;
  --body-lg-regular-font-weight: 400;
  --body-lg-regular-letter-spacing: 0px;
  --body-lg-regular-line-height: 28px;
  --body-md-semibold-font-family: "Inter", Helvetica;
  --body-md-semibold-font-size: 16px;
  --body-md-semibold-font-style: normal;
  --body-md-semibold-font-weight: 600;
  --body-md-semibold-letter-spacing: 0px;
  --body-md-semibold-line-height: 24px;
  --body-sm-semibold-font-family: "Inter", Helvetica;
  --body-sm-semibold-font-size: 14px;
  --body-sm-semibold-font-style: normal;
  --body-sm-semibold-font-weight: 600;
  --body-sm-semibold-letter-spacing: 0px;
  --body-sm-semibold-line-height: 20px;
  --body-xs-semibold-font-family: "Inter", Helvetica;
  --body-xs-semibold-font-size: 12px;
  --body-xs-semibold-font-style: normal;
  --body-xs-semibold-font-weight: 600;
  --body-xs-semibold-letter-spacing: 0px;
  --body-xs-semibold-line-height: 16px;
  --display-lg-bold-font-family: "Inter", Helvetica;
  --display-lg-bold-font-size: 48px;
  --display-lg-bold-font-style: normal;
  --display-lg-bold-font-weight: 700;
  --display-lg-bold-letter-spacing: -1.2px;
  --display-lg-bold-line-height: 60px;
  --display-xl-bold-font-family: "Inter", Helvetica;
  --display-xl-bold-font-size: 60px;
  --display-xl-bold-font-style: normal;
  --display-xl-bold-font-weight: 700;
  --display-xl-bold-letter-spacing: -1.5px;
  --display-xl-bold-line-height: 72px;
  --neutral-700: #374151;
  --neutral-900: #111827;
  --primary-100: #ede9fe;
  --primary-600: #7c3aed;
  --variable-collection-blue-m: #3173ff;
  --variable-collection-body-text: #111827;
  --variable-collection-dark-orange-m: #f44e18;
  --variable-collection-light-lilac-d: #ede9fe;
  --variable-collection-light-orange-m: #ff8a00;
  --variable-collection-lilac-d: #c4b5fd;
  --variable-collection-purple-m: #9924c8;
  --variable-collection-red-m: #ea1c33;
  --variable-collection-turquoise-d: #75e3ea;
  --white: #fff;
}

.visited\:text-current:visited {
  color: currentColor;
}

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.hover\:bg-violet-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:h-full {
  height: 100%;
}

.group:hover .group-hover\:text-white {
  color: var(--white);
}

@media (width >= 768px) {
  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mr-\[22px\] {
    margin-right: 22px;
  }

  .md\:mt-28 {
    margin-top: 7rem;
  }

  .md\:hidden {
    display: none;
  }

  .md\:min-w-\[540px\] {
    min-width: 540px;
  }

  .md\:min-w-\[768px\] {
    min-width: 768px;
  }

  .md\:max-w-\[400px\] {
    max-width: 400px;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:whitespace-nowrap {
    white-space: nowrap;
  }
}

@media (width >= 1280px) {
  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
}

@media (width >= 1408px) {
  .\31 \.5xl\:w-\[55\%\] {
    width: 55%;
  }
}

@media (width <= 1279px) {
  .max-xl\:left-\[25\%\] {
    left: 25%;
  }

  .max-xl\:mt-3 {
    margin-top: .75rem;
  }

  .max-xl\:mt-4 {
    margin-top: 1rem;
  }

  .max-xl\:items-center {
    align-items: center;
  }

  .max-xl\:justify-center {
    justify-content: center;
  }
}

@media (width <= 767px) {
  .max-md\:top-\[18\%\] {
    top: 18%;
  }

  .max-md\:mb-2 {
    margin-bottom: .5rem;
  }

  .max-md\:mb-4 {
    margin-bottom: 1rem;
  }

  .max-md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .max-md\:mt-12 {
    margin-top: 3rem;
  }

  .max-md\:mt-3 {
    margin-top: .75rem;
  }

  .max-md\:mt-4 {
    margin-top: 1rem;
  }

  .max-md\:mt-5 {
    margin-top: 1.25rem;
  }

  .max-md\:hidden {
    display: none;
  }

  .max-md\:max-h-\[900px\] {
    max-height: 900px;
  }

  .max-md\:min-h-\[1090px\] {
    min-height: 1090px;
  }

  .max-md\:w-\[50\%\] {
    width: 50%;
  }

  .max-md\:w-\[55\%\] {
    width: 55%;
  }

  .max-md\:w-\[65\%\] {
    width: 65%;
  }

  .max-md\:w-\[70\%\] {
    width: 70%;
  }

  .max-md\:w-\[80\%\] {
    width: 80%;
  }

  .max-md\:w-\[90\%\] {
    width: 90%;
  }

  .max-md\:min-w-\[145px\] {
    min-width: 145px;
  }

  .max-md\:text-wrap {
    text-wrap: wrap;
  }

  .max-md\:text-balance {
    text-wrap: balance;
  }

  .max-md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .max-md\:text-left {
    text-align: left;
  }

  .max-md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .max-md\:text-\[12px\] {
    font-size: 12px;
  }

  .max-md\:text-\[14\.5px\] {
    font-size: 14.5px;
  }

  .max-md\:text-\[15px\] {
    font-size: 15px;
  }

  .max-md\:text-\[16px\] {
    font-size: 16px;
  }

  .max-md\:text-\[1rem\] {
    font-size: 1rem;
  }

  .max-md\:text-\[3rem\] {
    font-size: 3rem;
  }

  .max-md\:text-\[40px\] {
    font-size: 40px;
  }

  .max-md\:text-\[55px\] {
    font-size: 55px;
  }

  .max-md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .max-md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .max-md\:leading-5 {
    line-height: 1.25rem;
  }

  .max-md\:leading-\[1\.15em\] {
    line-height: 1.15em;
  }

  .max-md\:leading-\[1\.3em\] {
    line-height: 1.3em;
  }

  .max-md\:leading-\[1\.3rem\] {
    line-height: 1.3rem;
  }

  .max-md\:leading-\[2\.90rem\] {
    line-height: 2.9rem;
  }

  .max-md\:leading-\[50px\] {
    line-height: 50px;
  }

  .max-md\:tracking-\[-0\.1px\] {
    letter-spacing: -.1px;
  }

  .max-md\:tracking-\[0px\] {
    letter-spacing: 0;
  }
}
/*# sourceMappingURL=index.3cf5fdc5.css.map */
